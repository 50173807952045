import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Disclaimer from '../../components/disclaimer';
import { Link } from 'gatsby';
import { TranslatedBlock } from 'src/components/language';
import EyeAMDEN from '../../images/eye-amd-en.svg';
import EyeAMDFR from '../../images/eye-amd-fr.svg';
import EyeDMEEN from '../../images/eye-dme-en.svg';
import EyeDMEFR from '../../images/eye-dme-fr.svg';
import EyeRVOEN from '../../images/eye-rvo-en.svg';
import EyeRVOFR from '../../images/eye-rvo-fr.svg';
import VideoThumbnailEN from '../../images/VABYSMO_MOA_Video_EN.png';
import VideoThumbnailFR from '../../images/VABYSMO_MOA_Video_FR.png';
import VabysmoBottle from '../../images/Vabysmo_bottle.svg';
import ThisWebsite1 from '../../images/this_website_1.svg';
import ThisWebsite2 from '../../images/this_website_2.svg';
import ThisWebsite3 from '../../images/this_website_3.svg';
import ViewNowArrow from '../../images/view_now_arrow.svg';
import OurVisionCareEN from '../../images/OurVisionCare_Final_KO_RGB_EN.svg';
import OurVisionCareFR from '../../images/OurVisionCare_Final_KO_RGB_FR.svg';
import { TranslatableText } from '../../components/language';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('home');
  }

  render() {
    let title = {
      english: 'What is VABYSMO?',
      french: 'Qu’est-ce que VABYSMO?',
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="homepage-header">
          <div className="container h-100">
            <div className="row">
              <div className="col-12 offset-lg-1 col-lg-11 offset-xl-3 col-xl-9 d-flex flex-column">
                <div className="d-flex flex-column flex-md-row align-items-center top">
                  <div className="d-flex justify-content-center px-5 py-4">
                    <img loading="lazy" src={VabysmoBottle} alt="" className={'m-auto'} />
                  </div>
                  <div className="px-4 py-4">
                    <TranslatedBlock language="english">
                      <h1 className="mb-3">What is VABYSMO?</h1>
                      <p>VABYSMO is a medicine that your doctor injects into your eye to treat eye diseases called:</p>
                      <ul>
                        <li>neovascular age-related macular degeneration (wet AMD)</li>
                        <li>diabetic macular edema (DME)</li>
                        <li>macular edema secondary to retinal vein occlusion (RVO)</li>
                      </ul>
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      <h1 className="mb-3">Qu’est-ce que VABYSMO?</h1>
                      <p>
                        VABYSMO est injecté dans l’œil par un médecin pour traiter les troubles oculaires suivants :
                      </p>
                      <ul>
                        <li>
                          dégénérescence maculaire liée à l’âge (DMLA) néovasculaire (forme exsudative ou humide);
                        </li>
                        <li>œdème maculaire diabétique (OMD);</li>
                        <li>l’œdème maculaire secondaire à une occlusion veineuse rétinienne (OVR).</li>
                      </ul>
                    </TranslatedBlock>
                    <div className="col-12 text-center">
                      <Link to="#video" className="text-uppercase btn btn-primary purple">
                        <TranslatableText
                          dictionary={{
                            english: 'Watch this video to learn how VABYSMO works',
                            french: 'Regardez cette vidéo pour voir comment VABYSMO agit.',
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="text-white bottom px-5 py-4">
                  <TranslatedBlock language="english">
                    <p>
                      Wet AMD, DME and RVO affect the macula, which is in the part of the retina responsible for central
                      vision.
                    </p>
                    <ul>
                      <li>Wet AMD is caused by abnormal blood vessels, which leak blood and fluid into the macula.</li>
                      <li>DME is caused by leaky blood vessels that cause swelling of the macula.</li>
                      <li>
                        RVO is caused by a blockage of the veins that carry blood away from the retina, which can lead
                        to fluid leakage and swelling of the macula.
                      </li>
                    </ul>
                  </TranslatedBlock>
                  <TranslatedBlock language="french">
                    <p>
                      La DMLA, l’OMD et l’OVR sont des troubles de la macula, partie de la rétine assurant la vision
                      centrale.
                    </p>
                    <ul>
                      <li>
                        La DMLA de forme exsudative ou humide est causée par le développement de vaisseaux sanguins
                        anormaux à partir desquels du sang et du liquide s’écoulent dans la macula.
                      </li>
                      <li>L’OMD est causé par des vaisseaux sanguins qui suintent et font gonfler la macula.</li>
                      <li>
                        L’OVR est causé par l’occlusion des veines qui drainent le sang de la rétine, ce qui peut
                        entraîner un suintement des vaisseaux et un gonflement de la macula.
                      </li>
                    </ul>
                  </TranslatedBlock>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="eyes py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4 px-3 py-4 text-center d-flex flex-column">
                <TranslatedBlock language="english">
                  <h2 className="flex-grow-1 align-self-center d-flex align-items-start">EYE AFFECTED WITH WET AMD</h2>
                  <img loading="lazy" src={EyeAMDEN} alt="" className={'m-auto img-fluid'} />
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2 className="flex-grow-1 align-self-center d-flex align-items-start">
                    ŒIL AFFECTÉ PAR LA DMLA DE FORME EXSUDATIVE OU HUMIDE
                  </h2>
                  <img loading="lazy" src={EyeAMDFR} alt="" className={'m-auto img-fluid'} />
                </TranslatedBlock>
              </div>
              <div className="col-12 col-lg-4 px-3 py-4 text-center d-flex flex-column">
                <TranslatedBlock language="english">
                  <h2 className="flex-grow-1 align-self-center d-flex align-items-start">EYE AFFECTED WITH DME</h2>
                  <img loading="lazy" src={EyeDMEEN} alt="" className={'m-auto img-fluid'} />
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2 className="flex-grow-1 align-self-center d-flex align-items-start">ŒIL AFFECTÉ PAR L’OMD</h2>
                  <img loading="lazy" src={EyeDMEFR} alt="" className={'m-auto img-fluid'} />
                </TranslatedBlock>
              </div>
              <div className="col-12 col-lg-4 px-3 py-4 text-center d-flex flex-column">
                <TranslatedBlock language="english">
                  <h2 className="flex-grow-1 align-self-center d-flex align-items-start">EYE AFFECTED WITH RVO</h2>
                  <img loading="lazy" src={EyeRVOEN} alt="" className={'m-auto img-fluid'} />
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2 className="flex-grow-1 align-self-center d-flex align-items-start">ŒIL AFFECTÉ PAR L’OVR</h2>
                  <img loading="lazy" src={EyeRVOFR} alt="" className={'m-auto img-fluid'} />
                </TranslatedBlock>
              </div>
              <div className="col-12 pb-2 pt-3 text-center">
                <TranslatableText
                  dictionary={{
                    english:
                      'For complete information on VABYSMO, consult the Patient Medication Information, or call 1-888-762-4388.',
                    french:
                      'Pour obtenir des renseignements complets sur VABYSMO, veuillez consulter la page Renseignements destinés aux patients ou composer le 1-888-762-4388.',
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="how-does">
          <div className="container">
            <div className="row">
              <div className="col-12 py-3 pt-5 mb-3 text-center secondary3-color">
                <TranslatedBlock language="english">
                  <h2>How does VABYSMO work?</h2>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2>Comment VABYSMO agit-il?</h2>
                </TranslatedBlock>
              </div>
              <div className="col-12">
                <div className="eyes-list row p-0">
                  <div className="col-12 col-md-6 col-lg p-0 mb-3">
                    <div className="eye p-4 py-5 p-xl-5 h-100">
                      <TranslatedBlock language="english">
                        <p>
                          VABYSMO specifically recognizes and blocks the activity of proteins known as{' '}
                          <b>angiopoietin-2</b> and <b>vascular endothelial growth factor A</b>.
                        </p>
                      </TranslatedBlock>
                      <TranslatedBlock language="french">
                        <p>
                          VABYSMO reconnaît particulièrement des protéines appelées <b>angiopoïétine-2</b> et{' '}
                          <b>facteur A de croissance de l’endothélium vasculaire</b>, et bloque leur activité.
                        </p>
                      </TranslatedBlock>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg p-0 mb-3">
                    <div className="eye p-4 py-5 p-xl-5 h-100">
                      <TranslatedBlock language="english">
                        <p>
                          In conditions like wet AMD, DME and RVO, these proteins can be present at high levels, and
                          this can cause the growth of abnormal blood vessels, damage to the normal vessels and/or
                          blockage of the blood vessels.
                        </p>
                      </TranslatedBlock>
                      <TranslatedBlock language="french">
                        <p>
                          Lors d’une maladie comme la DMLA exsudative ou humide, l’OMD ou l’OVR, ces protéines peuvent
                          être présentes à des concentrations élevées, ce qui peut entraîner le développement de
                          vaisseaux sanguins anormaux, de lésions aux vaisseaux normaux et/ou d’occlusions (blocages)
                          des vaisseaux sanguins.
                        </p>
                      </TranslatedBlock>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg p-0 mb-3">
                    <div className="eye p-4 py-5 p-xl-5 h-100">
                      <TranslatedBlock language="english">
                        <p>
                          The abnormal and damaged blood vessels leak into the retina causing swelling or damage to the
                          retina. This can make your vision worse.
                        </p>
                      </TranslatedBlock>
                      <TranslatedBlock language="french">
                        <p>
                          Ces modifications des vaisseaux sanguins peuvent provoquer des fuites dans la rétine, causant
                          un gonflement de la rétine ou des lésions des couches de la rétine et ainsi, des effets
                          négatifs sur la vision.
                        </p>
                      </TranslatedBlock>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg p-0 mb-3">
                    <div className="eye p-4 py-5 p-xl-5 h-100">
                      <TranslatedBlock language="english">
                        <p>
                          <b>VABYSMO</b> attaches to these proteins and can block their actions, and prevent abnormal
                          vessel growth, leakage, swelling and blockage.
                        </p>
                      </TranslatedBlock>
                      <TranslatedBlock language="french">
                        <p>
                          En se fixant à ces protéines, <b>VABYSMO</b> peut bloquer leurs actions et prévenir la
                          formation de vaisseaux sanguins anormaux, les fuites, le gonflement et l’occlusion.
                        </p>
                      </TranslatedBlock>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-8 offset-lg-2 text-center mt-3">
                <TranslatedBlock language="english">
                  <h3>
                    VABYSMO may improve disease and/or slow down your disease from getting worse. This means it can help
                    maintain or improve your vision.
                  </h3>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h3>
                    VABYSMO peut atténuer la maladie et/ou en ralentir l’aggravation, permettant ainsi de maintenir ou
                    même d’améliorer la vision.
                  </h3>
                </TranslatedBlock>
              </div>
            </div>
          </div>
        </section>

        <section id="video" className="video pb-5 primary22-bg">
          <div className="row">
            <div className="col-12 pt-5 pb-3 text-center secondary3-color">
              <TranslatedBlock language="english">
                <h2>Watch this video to learn how VABYSMO works</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2>Regardez cette vidéo pour voir comment VABYSMO agit.</h2>
              </TranslatedBlock>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <TranslatedBlock language="english">
                <video
                  controls
                  src="/video/VABYSMO_MOA_Video-86819E24v.mp4"
                  style={{ width: '100%' }}
                  poster={VideoThumbnailEN}
                >
                  <track
                    label="English"
                    kind="captions"
                    srcLang="en"
                    src="/video/VABYSMO_MOA_Video-86819E24v.vtt"
                  ></track>
                </video>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <video
                  controls
                  src="/video/VABYSMO_MOA_Video-86819F24v.mp4"
                  style={{ width: '100%' }}
                  poster={VideoThumbnailFR}
                >
                  <track
                    label="Français"
                    kind="captions"
                    srcLang="fr"
                    src="/video/VABYSMO_MOA_Video-86819F24v.vtt"
                  ></track>
                </video>
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <section className="container py-5">
          <div className="row">
            <div className="col-12 col-lg-7 mb-3 secondary3-color">
              <TranslatedBlock language="english">
                <h2>Is there anything I should tell my doctor before treatment with VABYSMO?</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2>
                  Y a-t-il quelque chose que je devrais porter à l’attention de mon médecin avant le traitement par
                  VABYSMO?
                </h2>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-lg-6">
              <TranslatedBlock language="english">
                <p>If any of the following apply to you, tell your doctor as you should not be given VABYSMO:</p>
                <ul>
                  <li>You have an active or suspected infection in or around the eye.</li>
                  <li>You have pain or redness in your eye (eye inflammation).</li>
                  <li>You are allergic to faricimab or any of the other ingredients in VABYSMO.</li>
                </ul>
                <p>
                  To help avoid side effects and ensure proper use, you should talk to your healthcare professional
                  before you take VABYSMO. Talk about any health conditions you have, including if you:
                </p>
                <ul>
                  <li>have glaucoma (an eye condition usually caused by high pressure in the eye);</li>
                </ul>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Si l’une des situations suivantes s’applique à vous, dites-le à votre médecin, car vous ne devrez pas
                  recevoir VABYSMO :
                </p>
                <ul>
                  <li>vous avez une infection évolutive ou soupçonnée dans l’œil ou autour de l’œil;</li>
                  <li>vous avez une douleur ou une rougeur dans l’œil (inflammation de l’œil);</li>
                  <li>vous êtes allergique au faricimab ou à n’importe quel autre ingrédient de VABYSMO.</li>
                </ul>
                <p>
                  Consultez votre professionnel de la santé avant de prendre ou de recevoir VABYSMO, afin d’aider à
                  éviter les effets secondaires et d’assurer la bonne utilisation du médicament. Informez votre
                  professionnel de la santé de tous vos problèmes et états de santé, notamment :
                </p>
                <ul>
                  <li>
                    si vous souffrez de glaucome (une maladie oculaire habituellement causée par une pression élevée
                    dans l’œil);
                  </li>
                </ul>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-lg-6">
              <TranslatedBlock language="english">
                <ul>
                  <li>
                    have a history of seeing flashes of light or floaters (dark floating spots) and if you have a sudden
                    increase in the size and number of floaters;
                  </li>
                  <li>
                    have had eye surgery in the last four weeks or if eye surgery is planned in the next four weeks;
                  </li>
                  <li>have ever had any eye diseases or eye treatments;</li>
                  <li>
                    have had a stroke or experienced transient signs of stroke (weakness or paralysis of limbs or face,
                    difficulty speaking or understanding);
                  </li>
                  <li>are pregnant or planning to become pregnant;</li>
                  <li>are breastfeeding.</li>
                </ul>
                <p>
                  Tell your healthcare professional about all the medicines you take, including any drugs, vitamins,
                  minerals, natural supplements or alternative medicines.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <ul>
                  <li>
                    s’il vous est déjà arrivé de voir des éclairs lumineux ou des corps flottants (des taches noires qui
                    bougent devant les yeux) et si les corps flottants sont soudainement devenus plus gros et plus
                    nombreux;
                  </li>
                  <li>
                    si vous avez subi une intervention chirurgicale aux yeux dans les 4 dernières semaines ou devez en
                    subir une dans les 4 prochaines semaines;
                  </li>
                  <li>
                    si vous avez déjà eu une maladie oculaire ou reçu un traitement pour les yeux, de quelque nature que
                    ce soit;
                  </li>
                  <li>
                    si vous avez subi un AVC ou avez présenté des signes transitoires d’AVC (faiblesse ou paralysie de
                    membres ou de la face, difficulté à parler ou à comprendre);
                  </li>
                  <li>si vous êtes enceinte ou prévoyez une grossesse;</li>
                  <li>si vous allaitez.</li>
                </ul>
                <p>
                  Mentionnez à votre professionnel de la santé tous les médicaments et produits de santé que vous
                  prenez, y compris : médicaments d’ordonnance et en vente libre, vitamines, minéraux, suppléments
                  naturels et produits de médecine douce.
                </p>
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <section className="side-effects">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4 p-5 pl-0">
                <TranslatedBlock language="english">
                  <h2 className="mb-3 secondary3-color">What are possible side effects from using VABYSMO?</h2>
                  <p>
                    The side effects with the VABYSMO injection are either from the medicine itself or from the
                    injection procedure and mostly affect the eye. These are not all the possible side effects; if you
                    experience any side effects not listed here, tell your healthcare professional. Contact your doctor
                    if any of the following side effects become severe.
                  </p>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2 className="mb-3 secondary3-color">
                    Quels sont les effets secondaires qui pourraient être associés à VABYSMO?
                  </h2>
                  <p>
                    Les effets secondaires observés avec VABYSMO sont dus au médicament en tant que tel ou à l’injection
                    et touchent principalement l’œil. Vous pourriez présenter des effets secondaires qui ne sont pas
                    mentionnés ci-dessous; si vous présentez des effets secondaires qui ne sont pas mentionnés ici,
                    communiquez avec votre professionnel de la santé. Communiquez avec votre médecin si l’un des effets
                    secondaires ci-dessous devient grave.
                  </p>
                </TranslatedBlock>
              </div>
              <div className="col-12 col-lg-8">
                <div className="wrapper p-4 m-5">
                  <div className="row pb-2 pt-4 pb-md-0 pt-md-3">
                    <TranslatedBlock language="english">
                      <div className="col-12">
                        <b className="secondary3-color">Common (may affect up to 1 in 10 people)</b>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul>
                          <li>Cloudy lens in the eye (cataract)</li>
                          <li>Detachment of the gel-like substance inside the eye (vitreous detachment)</li>
                          <li>
                            Bleeding from small blood vessels in the outer layer of the eye (conjunctival hemorrhage)
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="p-0 pt-md-3">
                          <li>Moving spots or dark shapes in your vision (vitreous floaters)</li>
                          <li>Eye pain</li>
                        </ul>
                      </div>
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      <div className="col-12">
                        <b className="secondary3-color">
                          Fréquents (susceptibles de toucher jusqu’à 1 personne sur 10)
                        </b>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul>
                          <li>Opacification du cristallin (cataracte)</li>
                          <li>Décollement de la substance gélatineuse à l’intérieur de l’œil (décollement du vitré)</li>
                          <li>
                            Saignement de petits vaisseaux sanguins dans la couche externe de l’œil (hémorragie
                            conjonctivale)
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="p-0 pt-md-3">
                          <li>
                            Taches ou formes foncées en mouvement dans votre champ de vision (corps flottants du vitré)
                          </li>
                          <li>Douleur oculaire</li>
                        </ul>
                      </div>
                    </TranslatedBlock>
                  </div>
                  <div className="row pb-2 pt-4 pb-md-0 pt-md-3">
                    <TranslatedBlock language="english">
                      <div className="col-12">
                        <b className="secondary3-color">Uncommon (may affect up to 1 in 100 people)</b>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul>
                          <li>
                            Scratched cornea, damage to the clear layer of the eyeball that covers the iris (corneal
                            abrasion)
                          </li>
                          <li>Eye irritation</li>
                          <li>Eye discomfort</li>
                          <li>Increased tear production (lacrimation increased)</li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="p-0 pt-md-3">
                          <li>Itching (eye pruritus)</li>
                          <li>Red eye (ocular/conjunctival hyperemia)</li>
                          <li>A feeling of having something in the eye (foreign body sensation in eye)</li>
                          <li>Pain during the procedure (procedural pain)</li>
                        </ul>
                      </div>
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      <div className="col-12">
                        <b className="secondary3-color">
                          Peu fréquents (susceptibles de toucher jusqu’à 1 personne sur 100)
                        </b>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul>
                          <li>
                            Égratignure de la cornée, lésions à la couche claire du globe oculaire qui couvre l’iris
                            (abrasion cornéenne)
                          </li>
                          <li>Irritation oculaire</li>
                          <li>Inconfort oculaire</li>
                          <li>Production plus abondante de larmes (larmoiement accru)</li>
                        </ul>
                      </div>
                      <div className="col-12 col-md-6">
                        <ul className="p-0 pt-md-3">
                          <li>Démangeaisons (prurit oculaire)</li>
                          <li>Œil rouge (hyperémie oculaire/conjonctivale)</li>
                          <li>Sensation d’avoir quelque chose dans l’œil (sensation d’un corps étranger dans l’œil)</li>
                          <li>Douleur pendant l’intervention</li>
                        </ul>
                      </div>
                    </TranslatedBlock>
                  </div>
                  <div className="row pb-2 pt-4 pb-md-0 pt-md-3">
                    <TranslatedBlock language="english">
                      <div className="col-12">
                        <b className="secondary3-color">Rare (may affect up to 1 in 1,000 people)</b>
                      </div>
                      <div className="col-12">
                        <ul>
                          <li>Temporary decreased sharpness of vision (visual acuity reduced transiently)</li>
                        </ul>
                      </div>
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      <div className="col-12">
                        <b className="secondary3-color">Rare (susceptible de toucher jusqu’à 1 personne sur 1 000)</b>
                      </div>
                      <div className="col-12">
                        <ul>
                          <li>Baisse transitoire de l’acuité visuelle</li>
                        </ul>
                      </div>
                    </TranslatedBlock>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 primary22-bg">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <TranslatedBlock language="english">
                  <h2 className="mb-3 secondary3-color">What are possible serious side effects from using VABYSMO?</h2>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2 className="mb-3 secondary3-color">
                    Quels sont les effets secondaires graves qui pourraient être associés à VABYSMO?
                  </h2>
                </TranslatedBlock>
                <TranslatedBlock language="english">
                  <p>
                    The following are possible serious side effects from using VABYSMO. If you experience any, tell your
                    healthcare professional.
                  </p>
                  <p>
                    <b>Common:</b>
                  </p>
                  <ul>
                    <li>Cataract (clouded, blurred or dim vision)</li>
                    <li>
                      Tear of one of the layers in the back of the eye (retinal pigment epithelial tear, a sudden
                      decrease or change in vision, wet AMD only)
                    </li>
                    <li>Increased pressure in the eye</li>
                  </ul>
                  <p>
                    <b>Uncommon:</b>
                  </p>
                  <ul>
                    <li>
                      Inflammation or infections (redness of the eye, eye pain, increased eye discomfort, blurred or
                      decreased vision, increased number of small particles in your vision, increased sensitivity to
                      light)
                    </li>
                    <li>
                      Tear or detachment of one of the layers at the back of the eye (the retina) (a sudden decrease or
                      change in vision, flashing lights, black spots)
                    </li>
                    <li>
                      Signs of stroke (weakness or paralysis of limbs or face, difficulty speaking or understanding,
                      sudden blurring or loss of vision)*
                    </li>
                    <li>Disturbed or blurred vision</li>
                    <li>Bleeding in the eye</li>
                    <li>Shock (hypersensitivity) – fast pulse, low blood pressure, sweating</li>
                  </ul>
                  <p>
                    <b>Unknown:</b>
                  </p>
                  <ul>
                    <li>
                      Retinal vasculitis (inflammation of the vessels of the retina) and/or retinal occlusive vasculitis
                      (blockage in a vein that drains blood from your retina)
                    </li>
                  </ul>
                  <p className="small">
                    * There is a potential risk of Arterial Thromboembolic Events (ATEs), including stroke, following
                    injection of VABYSMO into the eye.
                  </p>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <p>
                    Les effets secondaires graves mentionnés ci-dessous pourraient être associés au traitement par
                    VABYSMO. Le cas échéant, parlez‑en à votre professionnel de la santé.
                  </p>
                  <p>
                    <b>Fréquent</b>
                  </p>
                  <ul>
                    <li>Cataracte (vision brouillée, trouble ou assombrie)</li>
                    <li>
                      Déchirure d’une des couches qui tapissent l’arrière de l’œil (déchirure de l’épithélium
                      pigmentaire rétinien, baisse ou changement soudain de la vision, dans les cas de DMLA exsudative
                      ou humide seulement)
                    </li>
                    <li>Élévation de la pression dans l’œil</li>
                  </ul>
                  <p>
                    <b>Peu fréquent</b>
                  </p>
                  <ul>
                    <li>
                      Inflammation ou infections (rougeur de l’œil, douleur oculaire, inconfort oculaire accru, vision
                      trouble ou diminuée, augmentation du nombre de petites particules dans le champ de vision, plus
                      grande sensibilité à la lumière)
                    </li>
                    <li>
                      Déchirure ou décollement d’une des couches au fond de l’œil (rétine) (baisse ou changement soudain
                      de la vision, éclairs lumineux, taches noires)
                    </li>
                    <li>
                      Signes d’accident vasculaire cérébral (faiblesse ou paralysie des membres ou du visage, difficulté
                      à parler ou à comprendre, vision soudainement trouble ou perte soudaine de vision)*
                    </li>
                    <li>Vision perturbée ou trouble</li>
                    <li>Saignement de l’œil</li>
                    <li>Choc (hypersensibilité) – pouls rapide, tension artérielle basse, sueurs</li>
                  </ul>
                  <p>
                    <b>Inconnue</b>
                  </p>
                  <ul>
                    <li>
                      Vasculite rétinienne (inflammation des vaisseaux de la rétine) et/ou vasculite rétinienne
                      occlusive (obstruction d’une veine qui transporte le sang de la rétine)
                    </li>
                  </ul>
                  <p className="small">
                    * Il y a un risque potentiel de manifestations thromboemboliques artérielles, y compris d’accident
                    vasculaire cérébral, après l’injection de VABYSMO dans l’œil.
                  </p>
                </TranslatedBlock>
              </div>
              <div className="col-12 col-lg-6">
                <TranslatedBlock language="english">
                  <h2 className="mb-3 secondary3-color">Additional Safety Information:</h2>
                  <ul>
                    <li>
                      If you are pregnant, think you may be, or are planning to have a baby, ask your doctor for advice
                      before starting VABYSMO.
                    </li>
                    <li>
                      VABYSMO has not been studied in pregnant women. VABYSMO should be avoided during pregnancy unless
                      the potential benefit to the patient outweighs the potential risk to the unborn child.
                    </li>
                    <li>
                      If you could become pregnant, you must use an effective method of birth control during treatment
                      and for at least three months after stopping treatment. If you become pregnant or think you are
                      pregnant during treatment, tell your doctor right away.
                    </li>
                    <li>
                      Breastfeeding is not recommended during treatment because it is not known whether VABYSMO passes
                      into human milk. A risk to the breast-fed child cannot be excluded. You should not breastfeed your
                      child during treatment, and for at least one month after the last injection of VABYSMO. Ask your
                      doctor for advice before starting VABYSMO.
                    </li>
                    <li>
                      After your VABYSMO injection, you may have temporary vision problems (e.g., blurred vision). If
                      you experience these, do not drive or use machines as long as these last.
                    </li>
                  </ul>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h2 className="mb-3 secondary3-color">Renseignements supplémentaires sur l’innocuité :</h2>
                  <ul>
                    <li>
                      Si vous êtes enceinte, pensez être enceinte ou prévoyez une grossesse, demandez conseil à votre
                      médecin avant de commencer un traitement par VABYSMO.
                    </li>
                    <li>
                      VABYSMO n’a pas été étudié chez des femmes enceintes. L’utilisation de VABYSMO doit être évitée
                      pendant la grossesse, sauf si les bienfaits potentiels pour la patiente l’emportent sur les
                      risques potentiels pour l’enfant à naître.
                    </li>
                    <li>
                      Les femmes aptes à concevoir doivent utiliser une méthode de contraception efficace pendant le
                      traitement et pendant au moins 3 mois après l’arrêt du traitement par VABYSMO. Si vous concevez ou
                      pensez avoir conçu un enfant pendant le traitement, dites-le immédiatement à votre médecin.
                    </li>
                    <li>
                      L’allaitement n’est pas recommandé pendant un traitement par VABYSMO, car on ignore si VABYSMO
                      passe dans le lait maternel humain. Un risque pour l’enfant nourri au sein ne peut être exclu.
                      Vous ne devez pas allaiter durant le traitement par VABYSMO et pendant au moins un mois après la
                      dernière injection lorsque vous cessez le traitement. Demandez conseil à votre médecin avant de
                      commencer un traitement par VABYSMO.
                    </li>
                    <li>
                      Après une injection de VABYSMO, vous pourriez avoir des problèmes de vision passagers (une vision
                      trouble par exemple). Évitez de conduire un véhicule ou d’utiliser des machines tant et aussi
                      longtemps que ces problèmes n’auront pas disparu.
                    </li>
                  </ul>
                </TranslatedBlock>
              </div>
            </div>
          </div>
        </section>

        <section className="this-website container pb-5">
          <div className="row">
            <div className="col-12 py-5 text-center">
              <TranslatedBlock language="english">
                <h2>This website has:</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2>Sur ce site Web, vous pouvez télécharger :</h2>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-lg-4 py-5 py-lg-0 text-center">
              <div className="d-flex alig-items-center wrapper">
                <img loading="lazy" src={ThisWebsite1} alt="" className={'m-auto pb-3'} />
              </div>
              <p className="px-5">
                <TranslatableText
                  dictionary={{
                    english: 'A Risk Management Measures guide that you can download',
                    french: 'un guide sur les mesures d’atténuation des risques',
                  }}
                />
              </p>
              <Link to="/risk-management-measures/" className="d-flex justify-content-center view-now">
                <TranslatableText
                  dictionary={{
                    english: 'VIEW NOW ',
                    french: 'CONSULTER',
                  }}
                />
                <img loading="lazy" src={ViewNowArrow} alt="" className={''} />
              </Link>
            </div>
            <div className="col-12 col-lg-4 py-5 py-lg-0 text-center">
              <div className="d-flex alig-items-center wrapper">
                <img loading="lazy" src={ThisWebsite2} alt="" className={'m-auto pb-3'} />
              </div>
              <p className="px-5">
                <TranslatableText
                  dictionary={{
                    english: 'An audio version of the Risk Management Measures guide that you can download',
                    french: 'une version audio du guide sur les mesures d’atténuation des risques',
                  }}
                />
              </p>
              <Link to="/risk-management-measures/" className="d-flex justify-content-center view-now">
                <TranslatableText
                  dictionary={{
                    english: 'LISTEN NOW',
                    french: 'ÉCOUTER',
                  }}
                />
                <img loading="lazy" src={ViewNowArrow} alt="" className={''} />
              </Link>
            </div>
            <div className="col-12 col-lg-4 py-5 py-lg-0 text-center">
              <div className="d-flex alig-items-center wrapper">
                <img loading="lazy" src={ThisWebsite3} alt="" className={'m-auto pb-3'} />
              </div>
              <p className="px-5">
                <TranslatableText
                  dictionary={{
                    english: 'A guide to different community supports',
                    french: 'un guide des ressources de soutien communautaire',
                  }}
                />
              </p>
              <Link to="/community-support/" className="d-flex justify-content-center view-now">
                <TranslatableText
                  dictionary={{
                    english: 'VIEW NOW ',
                    french: 'CONSULTER',
                  }}
                />
                <img loading="lazy" src={ViewNowArrow} alt="" className={''} />
              </Link>
            </div>
            <div className="col-12 text-center">
              <Link to="/risk-management-measures/" className="text-uppercase btn btn-primary mt-5 purple">
                <TranslatableText
                  dictionary={{
                    english: 'see safety information',
                    french: 'Consulter les renseignements sur l’innocuité',
                  }}
                />
              </Link>
            </div>
          </div>
        </section>

        <section className="did-you-know">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-7 px-5 py-5 text-white">
                <TranslatedBlock language="english">
                  <p className="h2 pb-4">Did you know?</p>
                  <p className="mb-4">
                    Roche offers a Support Program for patients who are receiving VABYSMO eye injections. The program
                    staff can help you answer questions you may have about VABYSMO and make sure you have all the
                    information you may need. Use the link below or call the program at{' '}
                    <a href="tel:1-844-844-3371">
                      <b>1-844-844-3371</b>
                    </a>{' '}
                    to get more information.
                  </p>
                  <Link to="/ourvisioncare-program/" className="text-uppercase btn btn-primary yellow">
                    Learn more
                  </Link>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <p className="h2 pb-4">Le saviez-vous?</p>
                  <p className="mb-4">
                    Roche offre un programme d’assistance aux patients qui reçoivent la solution pour injection
                    intraoculaire VABYSMO. Le personnel du programme peut vous aider à obtenir des réponses à vos
                    questions sur VABYSMO et faire en sorte que vous disposiez de toute l’information dont vous avez
                    besoin. Cliquez sur le lien ci-dessous ou appelez un agent du programme au{' '}
                    <a href="tel:1-844-844-3371">
                      <b>1-844-844-3371</b>
                    </a>{' '}
                    pour obtenir de plus amples renseignements.
                  </p>
                  <Link to="/ourvisioncare-program/" className="text-uppercase btn btn-primary yellow">
                    Renseignements complémentaires
                  </Link>
                </TranslatedBlock>
              </div>
              <div className="col-12 col-lg-5 px-5 py-4 d-flex align-items-center">
                <TranslatedBlock language="english">
                  <img loading="lazy" src={OurVisionCareEN} alt="OurVisionCare logo" className={'py-5 py-lg-0'} />
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <img loading="lazy" src={OurVisionCareFR} alt="OurVisionCare logo" className={'py-5 py-lg-0'} />
                </TranslatedBlock>
              </div>
            </div>
          </div>
        </section>
        <Disclaimer />
      </Layout>
    );
  }
}

export default PatientPage;
